import React, { Component } from 'react';
import './App.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText'
import Axios from 'axios'

class Register extends Component {
    constructor (props) {
        super(props)
        this.state = {
            open: false,
            errorText: "",
            FirstName: "",
            LastName: "",
            Email: "",
            Password: "",
            Password2: ""
        }
    }

    openDialog = () => {
        this.setState({
            open: true
        })
    }

    closeDialog = () => {
        this.setState({
            open: false
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    register = () => {
        let {FirstName, LastName, Email, Password} = this.state
        Axios.post('/auth/register', {
            FirstName: FirstName,
            LastName: LastName,
            Password: Password,
            Email: Email
        })
            .then(res => {
                if (res.status === 201) {
                    this.setState({
                        open: false,
                        errorText: "",
                        FirstName: "",
                        LastName: "",
                        Email: "",
                        Password: ""
                    })
                    this.props.triggerSnackBar("You should have received an email from an @inspiringservice.org account, please verify your email address by clicking the link.")
                } else {
                    this.props.triggerSnackBar(res.data)
                }
            })
            .catch(err => {
                this.setState({
                    errorText: err.response.data
                })
            })
    }

    render() {
        let {FirstName, LastName, Email, Password, Password2} = this.state
        const disabled = !(FirstName !== "" && LastName !== "" && Email !== ""
            && Password !== "" && Password2 !== "" && (Password === Password2) && Password.length > 6)

        return (
            <span>
                <Button
                    className={'loginButton'}
                    onClick={this.openDialog}
                >
                    Register
                </Button>

                <Dialog
                    open={this.state.open}
                    onClose={this.closeDialog}
                    aria-labelledby="form-dialog-title"
                >
                        <DialogTitle id="form-dialog-title">Register</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.errorText}
                            </DialogContentText>
                            <TextField
                                autoFocus
                                value={this.state.FirstName}
                                onChange={this.handleChange}
                                margin="dense"
                                name="FirstName"
                                label="First Name"
                                type="text"
                                fullWidth
                            />
                            <TextField
                                autoFocus
                                value={this.state.LastName}
                                onChange={this.handleChange}
                                margin="dense"
                                name="LastName"
                                label="Last Name"
                                type="text"
                                fullWidth
                            />
                            <TextField
                                autoFocus
                                value={this.state.Email}
                                onChange={this.handleChange}
                                margin="dense"
                                name="Email"
                                label="Email"
                                type="email"
                                fullWidth
                            />
                            <TextField
                                autoFocus
                                value={this.state.Password}
                                onChange={this.handleChange}
                                margin="dense"
                                name="Password"
                                label="Password"
                                type="password"
                                fullWidth
                            />
                            <TextField
                                autoFocus
                                value={this.state.Password2}
                                onChange={this.handleChange}
                                margin="dense"
                                name="Password2"
                                label="Confirm Password"
                                type="password"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.register} color="secondary" disabled={disabled}>
                                Register
                            </Button>
                        </DialogActions>
                    </Dialog>
            </span>
        );
    }
}

export default Register;
