import React, { Component } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import Axios from 'axios'

import SessionCheck from './sessionCheck'
import Register from './register'

import Logo from "./greystacked.svg"
import Landing from "./BIGgreylanding-2.png"

const muiTheme = createMuiTheme({
    palette: {
        primary: {main: "#20C3F0", light: "#6ff6ff", dark: "#0092bd"},
        secondary: {main: "#FCAF23", light: "#ffe15a", dark: "#c48000"},
        text: {primary: "rgba(0, 0, 0, 0.87)", secondary: "rgba(0, 0, 0, 0.54)", disabled: "rgba(0, 0, 0, 0.38)", hint: "rgba(0, 0, 0, 0.38)"}
    }
});

class App extends Component {
    constructor (props) {
        super(props)
        let query
        if (window.location.search) {
            let search = window.location.search
            query = search.split("&").map((obj, index) => {
                return {[obj.slice(index===0?1:0, obj.indexOf("="))]: obj.slice(obj.indexOf("=")+1)}
            }).reduce((acc, obj) => {
                return {...obj, ...acc}
            }, {})
            if (query.name) {
                query.name = query.name.replace(/_/g, " ")
            }
            if (query.approved) {
               query.approved = query.approved === "true"
            }
        }

        this.state = {
            open: false,
            anchorEl: null,
            loginDialogOpen: false,
            username: "",
            password: "",
            verifyPassword: "",
            errorText: "",
            usernameError: false,
            passwordError: false,
            httpError: "",
            query: query
        }
    }

    handleClick = event => {
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget
        })
    }

    openLoginDialog = () => {
        this.setState({
            open: false,
            loginDialogOpen: true
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleClose = () => {
        this.setState({
            open: false,
            loginDialogOpen: false
        })
    }

    resetPassword = () => {
        this.setState({httpError: ""});
        if ( this.state.username !== "" ) {
            Axios.get(`/auth/resetPasswordRequest/${encodeURI(this.state.username.toLowerCase())}`)
                .then(res => {
                    this.setState({
                        errorText: `An email has been sent to ${this.state.username}`
                    })
                })
                .catch(err => {
                    if (err.response.status === 500) {
                        this.setState({
                            errorText: err.response.data.replace("undefined", ""),
                            password: ""
                        })
                    } else if ( err.response.status === 404 ) {
                        this.setState({httpError: "This email doesn't appear to be associated with an account."})
                    } else {
                        this.setState({httpError: "I'm sorry, something went wrong.  Please try again in a few minutes. (" + err + ")"})
                    }
                })
        } else {
            this.setState({
                errorText: "Email Address is required",
            })
        }
    }

    submitNewPassword = () => {
        const { password, query } = this.state;
        const {rk : resetKey} = query;

        Axios.post(`/auth/resetPassword/${resetKey}`, {
            password: password
        })
            .then(() => {
                this.setState({httpError: "Password Successfully Reset!", password: "", verifyPassword: "", query: null, username: this.state.query.email})
                this.openLoginDialog()
            })
            .catch(err => {
                let errorMessage;
                switch (err.response.status) {
                    case 403:
                        errorMessage = "Invalid attempt.  Try again by clicking 'Forgot Password' in Login menu";
                        this.openLoginDialog();
                        break;
                    case 400:
                        errorMessage = "Password cannot be the same as current password.";
                        break;
                    default:
                        errorMessage = "Something went wrong. Error Message: " + err.response.data;
                        break;
                }
                this.setState({httpError: errorMessage, password: "", verifyPassword: "", query: null, username: this.state.query.email})
            })

    }

    attemptLogin = () => {
        if (this.state.username && this.state.password) {
            Axios.get('/auth/basic', {auth: {username: this.state.username, password: this.state.password}})
                .then(res => {
                    window.location = res.data
                })
                .catch(err => {
                    if (err.response.status === 500) {
                        this.setState({
                            errorText: err.response.data.replace("undefined", ""),
                            password: ""
                        })
                    } else {
                        this.setState({httpError: "I'm sorry, something went wrong.  Please try again in a few minutes. (" + err + ")"})
                    }
                })
        } else {
            this.setState({
                errorText: "Email Address and Password are required",
                usernameError: this.state.username === "",
                passwordError: this.state.password === ""
            })
        }
    }

    triggerSnackBar = message => {
        this.setState({httpError: message})
    }

    render() {
        const disabledLogin = !(this.state.username !== "" && this.state.password.length > 6)
        return (
            <MuiThemeProvider theme={muiTheme}>
                <div className="App">
                    <nav>
                        <div className="leftNav">
                            <img src={Logo} className={"logo"} alt={"Inspiring Service"} />
                        </div>
                        <div className="rightNav">
                            <Button
                                aria-owns={'login-menu'}
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                className='loginButton'
                            >
                                Login
                            </Button>

                            <Register triggerSnackBar={this.triggerSnackBar} />

                            <Menu
                                open={this.state.open}
                                id='login-menu'
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClick}
                            >
                                <MenuItem onClick={this.openLoginDialog}>
                                    <ListItemText primary='Partners/Volunteers'/>
                                </MenuItem>
                                <a href="/auth/google" className="removeDecoration">
                                    <MenuItem onClick={this.handleClick}>
                                        <ListItemText primary="Inspiring Service Employees" />
                                    </MenuItem>
                                </a>
                            </Menu>

                        </div>
                    </nav>

                    <img src={Landing} alt={"Inspire. Empower. Engage."} className={"landing"}/>

                    <div className={"mission"}>
                        <Typography style={{color: "#FFFFFF"}}>
                            Our mission is to inspire and empower people and organizations to engage in volunteering that improves and strengthens their community, and themselves.
                        </Typography>
                    </div>


                    <Dialog
                        open={this.state.loginDialogOpen}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">Login</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.errorText}
                            </DialogContentText>
                            <TextField
                                autoFocus
                                value={this.state.username}
                                onChange={this.handleChange}
                                margin="dense"
                                name="username"
                                label="Email Address"
                                error={this.state.usernameError}
                                type="email"
                                fullWidth
                            />
                            <TextField
                                value={this.state.password}
                                onChange={this.handleChange}
                                margin="dense"
                                name="password"
                                label="Password"
                                type="password"
                                error={this.state.passwordError}
                                fullWidth
                            />
                            <DialogContentText>
                                To reset your password, enter your email and click "Forgot Password"
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={this.resetPassword}
                                disabled={this.state.username === ""}
                            >
                                Forgot Password
                            </Button>
                            <Button onClick={this.attemptLogin} disabled={disabledLogin} color="secondary">
                                Login
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={!!this.state.query && !!this.state.query.name}
                        onClose={() => {this.setState({query: null})}}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.query ? `${this.state.query.name}, thanks for verifying ${this.state.query.email}! ${this.state.query.approved ? "You can now log in!" : "Your account is pending approval."}`:""}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.setState({query: null})}} color="primary">
                                Close
                            </Button>
                            <Button disabled={this.state.query ? !this.state.query.approved : true} onClick={() => {this.setState({query: null}); this.openLoginDialog()}} color="secondary">
                                Login
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={!!this.state.query && !!this.state.query.rk}
                        disableBackdropClick
                        disableEscapeKeyDown
                        fullWidth
                        onClose={() => {this.setState({query: null})}}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter a new password
                            </DialogContentText>

                            <TextField
                                autoFocus
                                value={this.state.query ? this.state.query.email : ""}
                                disabled
                                onChange={this.handleChange}
                                margin="dense"
                                name="email"
                                label="email"
                                type="email"
                                fullWidth
                            />
                            <TextField
                                autoFocus
                                value={this.state.password}
                                onChange={this.handleChange}
                                margin="dense"
                                name="password"
                                label="New Password"
                                type="password"
                                fullWidth
                            />
                            <TextField
                                value={this.state.verifyPassword}
                                onChange={this.handleChange}
                                margin="dense"
                                name="verifyPassword"
                                label="Confirm New Password"
                                type="password"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.setState({query: null})}} color="primary">
                                Cancel
                            </Button>
                            <Button
                                disabled={this.state.verifyPassword.length > 6 ? !(this.state.password === this.state.verifyPassword) : true}
                                onClick={this.submitNewPassword}
                                color="secondary"
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={!!this.state.httpError}
                        ContentProps={{
                            'aria-describedby': 'httpError',
                        }}
                        message={<span id="httpError">{this.state.httpError}</span>}
                    />

                    <SessionCheck/>

                </div>
            </MuiThemeProvider>
        );
    }
}

export default App;
