import React from 'react'
import Axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'

class SessionCheck extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            open: false
        }
    }

    componentDidMount() {
        Axios.get('/api/checkSession')
            .then(() => {
                this.setState({open: true})
            })
            .catch(err => {})
    }

    handleClose = () => {
        this.setState({open: false})
    }

    handleLoggedIn = () => {
        window.location = "/Dashboard"
    }

    render () {
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
        >
            <DialogTitle>
                You're already logged in.  What would you like to do?
            </DialogTitle>
            <DialogActions>
                <Button onClick={this.handleClose}>Close</Button>
                <Button onClick={this.handleLoggedIn} variant="outlined" color="primary">Take me to the site</Button>
            </DialogActions>
        </Dialog>
    }

}

export default SessionCheck